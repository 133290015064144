import React from 'react';
import './Header.scss';
const Header = ({ isFreeTrial, isDefault, isBkids }) => {
  return (
    <div className="bg-blue text-white p-15 text-center">
      <div>
        <div className="global-nav-title h2 py-5 font-weight-normal lh-sm d-inline-block pl-40 text-truncate">
          Encyclopædia Britannica{' '}
        </div>

        <div className="line-2 text-uppercase font-12">
          Encyclopædia Britannica
          {isDefault && <span>&nbsp;Subscription</span>}
          {isBkids ? <span>&nbsp;Kids</span> : <span>&nbsp;Premium</span>}
          {isFreeTrial && <div>Free Trial</div>}
        </div>
      </div>
    </div>
  );
};

export default Header;
