import React from 'react';
import { ErrorMessage } from 'formik';
import { Input, FormFeedback } from '@britannica/ui';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { SHIPPING_ADDRESS, BILLING_ADDRESS } from '../../../App.constants';

const AddressForm = ({
  addressFor,
  showStatesList,
  Field,
  touched,
  errors,
  states,
  country,
  values,
  onSelectCountry,
  countries,
  setFieldValue,
  setFieldError,
}) => {
  let keyName = addressFor === SHIPPING_ADDRESS ? 'vin_Account_shippingAddress_' : 'vin_PaymentMethod_billingAddress_';

  return (
    <div>
      <div className="font-18 my-15 text-gray-600">
        {addressFor === SHIPPING_ADDRESS ? 'Shipping' : 'Billing'} Address
      </div>

      <div className="mb-15">
        <Field
          as={Input}
          size="sm"
          className={classnames(touched[`${keyName}name`] && !errors[`${keyName}name`] && 'border-green')}
          type="text"
          name={keyName + 'name'}
          id={keyName + 'name'}
          isInvalid={errors[`${keyName}name`]}
          placeholder="Full Name"
        />
        <ErrorMessage className="font-serif" name={keyName + 'name'} component={FormFeedback} />
      </div>
      {/* Name */}

      <div className="mb-15">
        <Field
          as={Input}
          size="sm"
          className={classnames(touched[`${keyName}addr1`] && !errors[`${keyName}addr1`] && 'border-green')}
          type="text"
          name={keyName + 'addr1'}
          id={keyName + 'addr1'}
          isInvalid={errors[`${keyName}addr1`]}
          placeholder="Address Line 1"
        />
        <ErrorMessage className="font-serif" name={keyName + 'addr1'} component={FormFeedback} />
      </div>
      {/* Address 1 */}

      <div className="mb-15">
        <Field
          as={Input}
          size="sm"
          className={classnames(touched[`${keyName}addr2`] && !errors[`${keyName}addr2`] && 'border-green')}
          type="text"
          name={keyName + 'addr2'}
          id={keyName + 'addr2'}
          isInvalid={errors[`${keyName}addr2`]}
          placeholder="Address Line 2"
        />
        <ErrorMessage className="font-serif" name={keyName + 'addr2'} component={FormFeedback} />
      </div>
      {/* Address 2 */}

      <div className="mb-15">
        <Field
          as={Input}
          size="sm"
          className={classnames(touched[`${keyName}city`] && !errors[`${keyName}city`] && 'border-green')}
          type="text"
          name={keyName + 'city'}
          id={keyName + 'city'}
          isInvalid={errors[`${keyName}city`]}
          placeholder="City"
        />
        <ErrorMessage className="font-serif" name={keyName + 'city'} component={FormFeedback} />
      </div>
      {/* City */}

      <div className="mb-15">
        <Field
          as={Input}
          size="sm"
          className={classnames(touched[`${keyName}postalCode`] && !errors[`${keyName}postalCode`] && 'border-green')}
          type="text"
          name={keyName + 'postalCode'}
          id={keyName + 'postalCode'}
          isInvalid={errors[`${keyName}postalCode`]}
          placeholder="Zip/Postal Code"
        />
        <ErrorMessage className="font-serif" name={keyName + 'postalCode'} component={FormFeedback} />
      </div>
      {/* Postal Code */}

      <div className="grid gx-20">
        <div className="col-100 col-sm-50 mb-15">
          {showStatesList ? (
            <Field
              component="select"
              name={keyName + 'district'}
              id={keyName + 'district'}
              placeholder="State/Province(outside US and Canada)"
              className={classnames(
                'form-control form-control-sm',
                (touched[`${keyName}district`] || touched[keyName + 'country']) &&
                  (errors[`${keyName}district`] ? 'border-red hide-check-mark' : 'border-green hide-check-mark')
              )}
            >
              <option value="NONE">No State Selected</option>
              {states?.map((state, index) => {
                const option = (
                  <option key={index} value={state.code}>
                    {state.description}
                  </option>
                );

                if (country === 'US' && state.parentCountry === 1) {
                  return option;
                } else if (country === 'CA' && state.parentCountry === 2) {
                  return option;
                } else if (state.parent === 0) {
                  return option;
                } else {
                  return null;
                }
              })}
            </Field>
          ) : (
            <Field
              as={Input}
              size="sm"
              type="text"
              className={classnames(
                'form-control form-control-sm',
                (touched[`${keyName}district`] || touched[keyName + 'country']) &&
                  (errors[`${keyName}district`] ? 'border-red' : 'border-green')
              )}
              name={keyName + 'district'}
              id={keyName + 'district'}
              onChange={(event) => {
                setFieldValue(`${keyName}district`, event.target.value);
              }}
              isInvalid={errors[`${keyName}district`]}
              placeholder="State/Province(outside US and Canada)"
            />
          )}
          <ErrorMessage className="font-serif" name={keyName + 'district'} component={FormFeedback} />
        </div>
        {/* State */}

        <div className="col-100 col-sm-50">
          <select
            className="form-control form-control-sm"
            type="select"
            name={keyName + 'country'}
            value={values[keyName + 'country']}
            id={keyName + 'country'}
            onChange={(event) => {
              setFieldValue(`${keyName}district`, '');
              setFieldError(`${keyName}district`, 'Required');
              setFieldValue(`${keyName}country`, event.target.value);
              onSelectCountry(event);
            }}
          >
            <option value="NONE">No Country Selected</option>
            {countries?.map((countryItem, index) => (
              <option key={index} value={countryItem.code}>
                {countryItem.description}
              </option>
            ))}
          </select>
        </div>
        {/* Country */}
      </div>

      <div className="mb-15">
        <Field
          as={Input}
          size="sm"
          className={classnames(touched[`${keyName}phone`] && !errors[`${keyName}phone`] && 'border-green')}
          type="text"
          name={keyName + 'phone'}
          id={keyName + 'phone'}
          isInvalid={errors[`${keyName}phone`]}
          placeholder="Phone Number (optional)"
        />
        <ErrorMessage className="font-serif" name={keyName + 'phone'} component={FormFeedback} />
      </div>
      {/* Phone */}
    </div>
  );
};

AddressForm.propTypes = {
  addressFor: PropTypes.string,
  showStatesList: PropTypes.bool.isRequired,
  Field: PropTypes.func.isRequired,
  touched: PropTypes.shape().isRequired,
  errors: PropTypes.shape().isRequired,
  states: PropTypes.array,
  country: PropTypes.string,
  values: PropTypes.shape().isRequired,
  onSelectCountry: PropTypes.func.isRequired,
  countries: PropTypes.array,
};

AddressForm.defaultProps = {
  addressFor: BILLING_ADDRESS,
  states: [],
  country: '',
  countries: [],
};

export default AddressForm;
