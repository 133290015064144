export const DefaultPartnerCode = 'BKIDS_THREE_TWO';

export const AppEndpoint = {
  BACKEND_API: process.env.REACT_APP_BACKEND_API,
  BACKEND_API_TEMP: process.env.REACT_APP_BACKEND_API,
  CDN_URL: process.env.REACT_APP_CDN_URL,
  VINDICIA_API: process.env.REACT_APP_VINDICIA_API,
  VINDICIA_CONFIG_API: process.env.REACT_APP_VINDICIA_CONFIG_API,
};

export const SHIPPING_ADDRESS = 'shipping_address';
export const BILLING_ADDRESS = 'billing_address';

export const BillingDuration = {
  DAY: 'Day',
  WEEK: 'Week',
  MONTH: 'Month',
  YEAR: 'Year',
};

export const CurrencyType = {
  VT: '_VT',
  // Not sure if there are others, but you can add them here if there are
};

export const BillingType = {
  DAILY: 'Daily',
  WEEKLY: 'Weekly',
  MONTHLY: 'Monthly',
  ANNUAL: 'Annual',
};
