import React, { useState, useEffect, useContext, Fragment } from 'react';
import classnames from 'classnames';
import './TabCard.scss';
import { CurrencyType, BillingDuration, BillingType } from '../../../App.constants';
import { GlobalContext } from '../../../context/GlobalState';
import Util from '../../../services/Util';

const TabCard = ({ plan, onSelectProductId, onSelectPbId, onSelectBillId, onSelectUnit, onSelectVerbiage, index }) => {
  const [tabActive, setTabActive] = useState('');
  const { tempActiveProductId, selectTempActiveProductId } = useContext(GlobalContext);

  //initialized the first selected plan, if it has year only then set annul to true
  useEffect(() => {
    if (index === 0) {
      if (plan.productBillings[0].billingPlan.currencyType === CurrencyType.VT) {
        selectBillingPlan(
          plan.id,
          plan.productBillings[0].id,
          plan.productBillings[0].billingPlan.id,
          plan.productBillings[0].billingPlan.graDurUnit,
          plan.productBillings[0].billingPlan.verbiage
        );
      } else {
        selectBillingPlan(
          plan.id,
          plan.productBillings[0].id,
          plan.productBillings[0].billingPlan.id,
          plan.productBillings[0].billingPlan.recDurUnit,
          plan.productBillings[0].billingPlan.verbiage
        );
      }
    } else {
      if (plan.productBillings[0].billingPlan.currencyType === CurrencyType.VT) {
        setTabActive(Util.membershipName(plan.productBillings[0].billingPlan.graDurUnit));
      } else {
        setTabActive(Util.membershipName(plan.productBillings[0].billingPlan.recDurUnit));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [index]);

  const onPlanSelectHandler = (plan) => {
    if (!tempActiveProductId || tempActiveProductId !== plan.id) {
      if (plan.productBillings[0].billingPlan.currencyType === CurrencyType.VT) {
        selectBillingPlan(
          plan.id,
          plan.productBillings[0].id,
          plan.productBillings[0].billingPlan.id,
          plan.productBillings[0].billingPlan.graDurUnit,
          plan.productBillings[0].billingPlan.verbiage
        );
      } else {
        selectBillingPlan(
          plan.id,
          plan.productBillings[0].id,
          plan.productBillings[0].billingPlan.id,
          plan.productBillings[0].billingPlan.recDurUnit,
          plan.productBillings[0].billingPlan.verbiage
        );
      }
    }
  };

  const selectBillingPlan = (productId, pbId, billId, unit, verbiage) => {
    setTabActive(Util.membershipName(unit));
    selectTempActiveProductId(productId);

    onSelectProductId(productId);
    onSelectPbId(pbId);
    onSelectBillId(billId);
    onSelectUnit(unit);
    onSelectVerbiage(verbiage);
  };

  const displayCurrencyType = (currencyType) => {
    if (currencyType === 'AUD') {
      return 'AU$';
    } else if (currencyType === 'USD') {
      return '$';
    } else if (currencyType === 'EUR') {
      return '€';
    } else if (currencyType === 'GBP') {
      return '£';
    }
  };

  const isActive = (productBillings, activeTab) => {
    if (activeTab) {
      let tempUnit = '';
      if (productBillings.billingPlan.currencyType === CurrencyType.VT) {
        tempUnit = productBillings.billingPlan.graDurUnit;
      } else {
        tempUnit = productBillings.billingPlan.recDurUnit;
      }

      if (tempUnit === BillingDuration.YEAR) {
        return activeTab === BillingType.ANNUAL ? '' : 'd-none';
      }

      if (tempUnit === BillingDuration.MONTH) {
        return activeTab === BillingType.MONTHLY ? '' : 'd-none';
      }

      if (tempUnit === BillingDuration.WEEK) {
        return activeTab === BillingType.WEEKLY ? '' : 'd-none';
      }

      if (tempUnit === BillingDuration.DAY) {
        return activeTab === BillingType.DAILY ? '' : 'd-none';
      }
    }
  };

  return (
    <div className="membership_col col-100 col-sm-50 col-md-33">
      <input className="d-none" type="radio" defaultChecked={index === 0} id={plan.id} name="plan" value={plan.id} />
      <label className="position-relative w-100" onClick={() => onPlanSelectHandler(plan)} htmlFor={plan.id}>
        {plan?.productBillings.map((productBilling, index) => (
          <div
            key={productBilling.id}
            className={classnames(
              'plan_description_container border border-width-2 text-center pb-60 px-10',
              isActive(productBilling, tabActive)
            )}
          >
            <div
              className={classnames('h1 font-sans-serif mt-20 font-weight-bold', {
                h3: plan.displayDescription.length > 40,
              })}
            >
              {plan.displayDescription}
            </div>

            {productBilling?.billingPlan?.currencyType === CurrencyType.VT ? (
              <div className="font-18 mt-10">
                {productBilling && productBilling.billingPlan.graDur && productBilling.billingPlan.graDurUnit
                  ? `${productBilling.billingPlan.graDur} ${productBilling.billingPlan.graDurUnit} free trial - Prepaid`
                  : ''}
              </div>
            ) : (
              <div className="font-18 mt-10">
                {/* <!-- grace period --> */}
                {productBilling && productBilling.billingPlan.graDur && productBilling.billingPlan.graDurUnit
                  ? `${productBilling.billingPlan.graDur} ${productBilling.billingPlan.graDurUnit} free trial then, `
                  : ''}

                {/* <!-- intro price --> */}
                {productBilling && productBilling.billingPlan.int1DurUnit && productBilling.billingPlan.int1Amount
                  ? `${
                      displayCurrencyType(productBilling.billingPlan.currencyType) +
                      productBilling.billingPlan.int1Amount
                    } / ${productBilling.billingPlan.int1DurUnit}`
                  : `${
                      displayCurrencyType(productBilling.billingPlan.currencyType) +
                      productBilling.billingPlan.recAmount
                    } / ${productBilling.billingPlan.recDurUnit}`}
              </div>
            )}

            <div className="font-12 mt-15">
              <a
                href="#tnc"
                onClick={(ev) => {
                  ev.preventDefault();
                  ev.stopPropagation();
                  window.scrollTo({
                    top: document.getElementById('tnc').offsetTop,
                    left: 0,
                    behavior: 'smooth',
                  });
                }}
              >
                See Terms and Conditions below
              </a>
            </div>

            <div className="font-12 mt-15">
              {productBilling.billingPlan.displayDescription ? productBilling.billingPlan.displayDescription : ''}
            </div>
          </div>
        ))}

        {plan.productBillings.length > 1 ? (
          <div className="bottom-container grid g-0 text-uppercase w-100 position-absolute bottom-0 font-weight-bold font-14 text-center d-flex">
            {/*eslint-disable-next-line array-callback-return */}
            {Object.keys(plan.productBillings).map((key) => (
              <Fragment key={'pb-' + key}>
                {plan.productBillings[key].billingPlan.currencyType === CurrencyType.VT ? (
                  <>
                    {plan.productBillings[key].billingPlan.graDurUnit === BillingDuration.YEAR && (
                      <div
                        className={classnames(
                          'col tab-container border pt-5 border-top-0 border-width-2',
                          tabActive === BillingType.ANNUAL
                            ? 'tab-active bg-white'
                            : 'tab-not-active bg-gray-100 border-0'
                        )}
                        onClick={() =>
                          selectBillingPlan(
                            plan.id,
                            plan.productBillings[key].id,
                            plan.productBillings[key].billingPlan.id,
                            plan.productBillings[key].billingPlan.graDurUnit,
                            plan.productBillings[key].billingPlan.verbiage
                          )
                        }
                      >
                        ANNUAL
                      </div>
                    )}

                    {plan.productBillings[key].billingPlan.graDurUnit === BillingDuration.MONTH && (
                      <div
                        className={classnames(
                          'col tab-container border pt-5 border-top-0 border-width-2',
                          tabActive === BillingType.MONTHLY
                            ? 'tab-active bg-white'
                            : 'tab-not-active bg-gray-100 border-0'
                        )}
                        onClick={() =>
                          selectBillingPlan(
                            plan.id,
                            plan.productBillings[key].id,
                            plan.productBillings[key].billingPlan.id,
                            plan.productBillings[key].billingPlan.graDurUnit,
                            plan.productBillings[key].billingPlan.verbiage
                          )
                        }
                      >
                        MONTHLY
                      </div>
                    )}

                    {plan.productBillings[key].billingPlan.graDurUnit === BillingDuration.WEEK && (
                      <div
                        className={classnames(
                          'col tab-container border pt-5 border-top-0 border-width-2',
                          tabActive === BillingType.WEEKLY
                            ? 'tab-active bg-white'
                            : 'tab-not-active bg-gray-100 border-0'
                        )}
                        onClick={() =>
                          selectBillingPlan(
                            plan.id,
                            plan.productBillings[key].id,
                            plan.productBillings[key].billingPlan.id,
                            plan.productBillings[key].billingPlan.graDurUnit,
                            plan.productBillings[key].billingPlan.verbiage
                          )
                        }
                      >
                        WEEKLY
                      </div>
                    )}

                    {plan.productBillings[key].billingPlan.graDurUnit === BillingDuration.DAY && (
                      <div
                        className={classnames(
                          'col tab-container border pt-5 border-top-0 border-width-2',
                          tabActive === BillingType.DAILY
                            ? 'tab-active bg-white'
                            : 'tab-not-active bg-gray-100 border-0'
                        )}
                        onClick={() =>
                          selectBillingPlan(
                            plan.id,
                            plan.productBillings[key].id,
                            plan.productBillings[key].billingPlan.id,
                            plan.productBillings[key].billingPlan.graDurUnit,
                            plan.productBillings[key].billingPlan.verbiage
                          )
                        }
                      >
                        DAILY
                      </div>
                    )}
                  </>
                ) : (
                  <>
                    {plan.productBillings[key].billingPlan.recDurUnit === BillingDuration.YEAR && (
                      <div
                        className={classnames(
                          'col tab-container border pt-5 border-top-0 border-width-2',
                          tabActive === BillingType.ANNUAL
                            ? 'tab-active bg-white'
                            : 'tab-not-active bg-gray-100 border-0'
                        )}
                        onClick={() =>
                          selectBillingPlan(
                            plan.id,
                            plan.productBillings[key].id,
                            plan.productBillings[key].billingPlan.id,
                            plan.productBillings[key].billingPlan.recDurUnit,
                            plan.productBillings[key].billingPlan.verbiage
                          )
                        }
                      >
                        ANNUAL
                      </div>
                    )}

                    {plan.productBillings[key].billingPlan.recDurUnit === BillingDuration.MONTH && (
                      <div
                        className={classnames(
                          'col tab-container border pt-5 border-top-0 border-width-2',
                          tabActive === BillingType.MONTHLY
                            ? 'tab-active bg-white'
                            : 'tab-not-active bg-gray-100 border-0'
                        )}
                        onClick={() =>
                          selectBillingPlan(
                            plan.id,
                            plan.productBillings[key].id,
                            plan.productBillings[key].billingPlan.id,
                            plan.productBillings[key].billingPlan.recDurUnit,
                            plan.productBillings[key].billingPlan.verbiage
                          )
                        }
                      >
                        MONTHLY
                      </div>
                    )}

                    {plan.productBillings[key].billingPlan.recDurUnit === BillingDuration.WEEK && (
                      <div
                        className={classnames(
                          'col tab-container border pt-5 border-top-0 border-width-2',
                          tabActive === BillingType.WEEKLY
                            ? 'tab-active bg-white'
                            : 'tab-not-active bg-gray-100 border-0'
                        )}
                        onClick={() =>
                          selectBillingPlan(
                            plan.id,
                            plan.productBillings[key].id,
                            plan.productBillings[key].billingPlan.id,
                            plan.productBillings[key].billingPlan.recDurUnit,
                            plan.productBillings[key].billingPlan.verbiage
                          )
                        }
                      >
                        WEEKLY
                      </div>
                    )}

                    {plan.productBillings[key].billingPlan.recDurUnit === BillingDuration.DAY && (
                      <div
                        className={classnames(
                          'col tab-container border pt-5 border-top-0 border-width-2',
                          tabActive === BillingType.DAILY
                            ? 'tab-active bg-white'
                            : 'tab-not-active bg-gray-100 border-0'
                        )}
                        onClick={() =>
                          selectBillingPlan(
                            plan.id,
                            plan.productBillings[key].id,
                            plan.productBillings[key].billingPlan.id,
                            plan.productBillings[key].billingPlan.recDurUnit,
                            plan.productBillings[key].billingPlan.verbiage
                          )
                        }
                      >
                        DAILY
                      </div>
                    )}
                  </>
                )}
              </Fragment>
            ))}
          </div>
        ) : (
          <div className="text-uppercase w-100 position-absolute bottom-0 font-weight-bold font-14 pb-20 text-center">
            {plan.productBillings[0].billingPlan.currencyType === CurrencyType.VT
              ? Util.membershipName(plan.productBillings[0].billingPlan.graDurUnit)
              : Util.membershipName(plan.productBillings[0].billingPlan.recDurUnit)}
          </div>
        )}
      </label>
    </div>
  );
};

export default TabCard;
