import React, { useContext } from 'react';
import { GlobalContext } from '../../context/GlobalState';
import Util from '../../services/Util';
import { CurrencyType } from '../../App.constants';

const Terms = ({ verbiage }) => {
  const { selectedProduct, selectedBill } = useContext(GlobalContext);

  return (
    <div id="tnc">
      {verbiage && (
        <>
          <div className="font-18 font-weight-bold text-gray-600">Terms and Conditions</div>
          {selectedProduct && selectedBill && (
            <div className="font-14 my-10 text-gray-400">
              <b>Item:</b> {selectedProduct.displayDescription}
              {selectedBill.billingPlan.currencyType === CurrencyType.VT
                ? Util.membershipName(selectedBill.billingPlan.graDurUnit)
                : Util.membershipName(selectedBill.billingPlan.recDurUnit)}
            </div>
          )}
          <div className="font-14 mt-5 text-gray-400" dangerouslySetInnerHTML={{ __html: verbiage }} />
        </>
      )}

      <div className="font-14 text-gray-400">
        <a target="_blank" href="http://corporate.britannica.com/privacy.html" rel="noopener noreferrer">
          Privacy Policy{' '}
        </a>
        | &copy;{new Date().getFullYear()} Encyclop&aelig;dia Britannica{' '}
      </div>
    </div>
  );
};

export default Terms;
