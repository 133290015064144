import React, { useEffect, useState, useContext } from 'react';
import { Button } from '@britannica/ui';
import { useLocation } from 'react-router-dom';
import CircularProgress from '@material-ui/core/CircularProgress';
import SubscribeApi from '../../../services/SubscribeApi';
import LoadingContainer from '../../../components/Loading/LoadingContainer';
import TabCard from './TabCard';
import Util from '../../../services/Util';
import { CurrencyType } from '../../../App.constants';
import { GlobalContext } from '../../../context/GlobalState';
import ErrorPrompt from '../../../components/ErrorPrompt/ErrorPrompt';

const Membership = ({ onSubmitPlan, jwt, onUpdateWebsessionId, promoCode, handleUnAuthorized }) => {
  const {
    selectProductId,
    selectPbId,
    selectBillId,
    selectUnit,
    verbiage,
    selectVerbiage,
    selectedProduct,
    selectProduct,
    selectedBill,
    selectBill,
  } = useContext(GlobalContext);

  const [membershipPlans, setMembershipPlans] = useState([]);
  const [errorFetchingMembershipPlans, setErrorFetchingMembershipPlans] = useState(false);
  const [isUpdating, setIsUpdating] = useState(false);
  const [updateError, setUpdateError] = useState(false);

  const [tempProductId, setTempProductId] = useState(null);
  const [tempPbId, setTempPbId] = useState(null);
  const [tempBillId, setTempBillId] = useState(null);
  const [tempUnit, setTempUnit] = useState(null);
  const [tempVerbiage, setTempVerbiage] = useState(null);
  const location = useLocation();

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    let partnerCode = params.get('partnerCode');

    if (promoCode?.length) {
      SubscribeApi.getPromotionalMembershipPlans(promoCode).then(
        (data) => {
          if (data.status === 'SUCCESS' && data.result.products) {
            setMembershipPlans(data.result.products);
          }
          if (data.status === 'FAIL') {
            setErrorFetchingMembershipPlans({
              message: data.errorResponse?.errorMessage,
            });
          }
        },
        (error) => {
          setErrorFetchingMembershipPlans(error);
        }
      );
    } else {
      SubscribeApi.getmembershipPlans(partnerCode).then(
        (data) => {
          if (data.status === 'SUCCESS' && data.result.products) {
            setMembershipPlans(data.result.products);
          }
          if (data.status === 'FAIL') {
            setErrorFetchingMembershipPlans({
              message: data.errorResponse?.errorMessage,
            });
          }
        },
        (error) => {
          setErrorFetchingMembershipPlans(error);
        }
      );
    }
  }, [location, promoCode]);

  const updatePlan = () => {
    const params = new URLSearchParams(window.location.search);
    let partnerCode = params.get('partnerCode');
    setIsUpdating(true);
    SubscribeApi.InitializeSubscription({
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${jwt}`,
      },
      body: JSON.stringify({
        productId: tempProductId,
        pbId: tempPbId,
        billId: tempBillId,
        partnerCode,
        promotionCode: promoCode,
      }),
    }).then(
      (data) => {
        if (data && data.sucessResponse && data.sucessResponse.webSessionId) {
          onUpdateWebsessionId(data.sucessResponse.webSessionId);
          setUpdateError(false);
          // setWebSessionId(data.sucessResponse.webSessionId);
          // setTimeout(() => {
          //   window.vindicia.setup(vindiciaOptions);
          // }, 200);
        } else {
          setIsUpdating(false);
          setUpdateError('Error in intilization');
        }
        setIsUpdating(false);
        //console.log("InitializeSubscription", data);
        updateContextVariables();
        onSubmitPlan();
      },
      (error) => {
        if (error.errorResponse?.code === 'UnAuthorized') {
          setUpdateError('Not a valid Request. Please login before performing this action.');
          handleUnAuthorized();
        } else {
          setUpdateError(Util.getErrorMessage(error));
        }

        setIsUpdating(false);
      }
    );
  };

  const sortedProductBillings = (plan) => {
    const tempProductBillings = plan?.productBillings.slice();
    const clonedPlan = JSON.parse(JSON.stringify(plan));
    const sortOrder = ['Year', 'Month', 'Week', 'Day'];

    clonedPlan.productBillings = [];

    sortOrder.forEach((order) => {
      tempProductBillings.forEach((billing) => {
        if (billing.billingPlan.currencyType === CurrencyType.VT) {
          if (order === billing.billingPlan.graDurUnit) {
            clonedPlan.productBillings.push(billing);
          }
        } else {
          if (order === billing.billingPlan.recDurUnit) {
            clonedPlan.productBillings.push(billing);
          }
        }
      });
    });
    return clonedPlan;
  };

  const onSelectProductId = (productId) => {
    setTempProductId(productId);
  };
  const onSelectPbId = (pbId) => {
    setTempPbId(pbId);
  };
  const onSelectBillId = (billId) => {
    setTempBillId(billId);
  };
  const onSelectUnit = (unit) => {
    setTempUnit(unit);
  };
  const onSelectVerbiage = (verbiage) => {
    setTempVerbiage(verbiage);
  };

  useEffect(() => {
    // Update Product Name, Duration & Verbiage for Footer TnC on Initial Load
    if (!selectedProduct && !selectedBill) {
      updateProductAndBill();
    }
    if (!verbiage) {
      selectVerbiage(tempVerbiage);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tempBillId]);

  const updateProductAndBill = () => {
    if (membershipPlans) {
      membershipPlans.forEach((ele) => {
        if (ele.id === tempProductId) {
          selectProduct(ele);
          ele.productBillings.forEach((ele2) => {
            if (ele2.id === tempPbId) {
              selectBill(ele2);
            }
          });
        }
      });
    }
  };

  const updateContextVariables = () => {
    selectProductId(tempProductId);
    selectPbId(tempPbId);
    selectBillId(tempBillId);
    selectUnit(tempUnit);
    selectVerbiage(tempVerbiage);

    updateProductAndBill();
  };

  const continuePlanAction = () => {
    updateContextVariables();
    onSubmitPlan();
  };

  return (
    <LoadingContainer
      isLoading={!membershipPlans.length}
      hasError={!!errorFetchingMembershipPlans}
      errorMessage={errorFetchingMembershipPlans.message || 'Error fetching membership plans'}
    >
      <form>
        <div className="grid g-20">
          {!errorFetchingMembershipPlans &&
            membershipPlans.map((plan, index) => (
              <TabCard
                index={index}
                key={'key-' + plan.id}
                plan={sortedProductBillings(plan)}
                onSelectProductId={onSelectProductId}
                onSelectPbId={onSelectPbId}
                onSelectBillId={onSelectBillId}
                onSelectUnit={onSelectUnit}
                onSelectVerbiage={onSelectVerbiage}
              />
            ))}
        </div>

        {updateError && <ErrorPrompt displayError={true} customErrorMessage={updateError} />}

        <div className="mt-4 mb-4 my-25">
          {jwt ? (
            <Button
              label={isUpdating ? 'Updating Plan' : 'Update'}
              color="blue"
              size="sm"
              disabled={!tempBillId}
              onClick={(e) => {
                e.preventDefault();
                updatePlan();
              }}
              type="submit"
              value="Submit"
              className="btn btn-primary membership-button"
            >
              {isUpdating ? (
                <>
                  <span>Updating Plan</span>
                  <CircularProgress className="ml-10" color="inherit" size={16} />
                </>
              ) : (
                'Update'
              )}
            </Button>
          ) : (
            <Button
              label="Select & Continue"
              color="blue"
              size="sm"
              disabled={!tempBillId}
              onClick={(e) => {
                e.preventDefault();
                continuePlanAction();
              }}
              type="submit"
              value="Submit"
              className="btn btn-primary membership-button"
            >
              Select & Continue
            </Button>
          )}
        </div>
      </form>
    </LoadingContainer>
  );
};

export default Membership;
