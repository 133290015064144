import { gray700, gray400, gray200, gray100, green, red } from '@britannica/ui/src/scss/exports/colors.scss';
import { AppEndpoint } from '../../../App.constants';

export const vindiciaOptions = {
  // Options

  formId: 'mainForm', // dom element of form
  vindiciaServer: 'secure.staging.us-west.vindicia.com',
  hostedFields: {
    cardNumber: {
      selector: '#vin_PaymentMethod_creditCard_account',
      placeholder: 'Enter Credit Card Number', // optional field
    },
    expirationDate: {
      // required field or use expirationMonth and expirationYear

      selector: '#vin_PaymentMethod_creditCard_expirationDate',
      placeholder: 'Expiration Date MM/YY', // optional field
      format: 'MM/YY', // optional, default is MM/YY
    },

    cvn: {
      selector: '#vin_PaymentMethod_nameValues_cvn',
      placeholder: 'Enter CVV', // optional field
    },

    styles: {
      // optional

      input: {
        width: '100%',
        'font-family': "'Helvetica Neue',Helvetica,Arial,sans-serif",
        'font-size': '14px',
        color: gray700,
        height: '42px',
        padding: '10px 17px',
        margin: '10px 0px',
        'line-height': '1.42857',
        border: `1px solid ${gray400}`,
        'border-radius': '4px',
        'box-shadow': '0px 1px 1px rgba(0,0,0,0.075) inset',
        '-webkit-transition': 'border-color 0.15s ease-in-out 0s, box-shadow 0.15s ease-in-out 0s',
        transition: 'border-color 0.15s ease-in-out 0s, box-shadow 0.15s ease-in-out 0s',
      },

      select: {
        width: '100%',
        'font-family': "'Helvetica Neue',Helvetica,Arial,sans-serif",
        'font-size': '14px',
        color: gray700,
        height: '34px',
        padding: '6px 12px',
        margin: '5px 0px',
        'line-height': '1.42857',
        border: `1px solid ${gray200}`,
        'border-radius': '4px',
        'box-shadow': '0px 1px 1px rgba(0,0,0,0.075) inset',
        '-webkit-transition': 'border-color 0.15s ease-in-out 0s, box-shadow 0.15s ease-in-out 0s',
        transition: 'border-color 0.15s ease-in-out 0s, box-shadow 0.15s ease-in-out 0s',
      },

      ':focus': {
        // optional

        'border-color': gray100,
        outline: '0',
        '-webkit-box-shadow': '0 0 0 0.2rem rgba(14, 63, 112, 0.25)',
        'box-shadow': '0 0 0 0.2rem rgba(14, 63, 112, 0.25)',
        color: '#4d4d4d',
      },

      '.valid': {
        // optional

        'border-color': green,
      },

      '.notValid': {
        // optional

        'border-color': red,
      },
    },
  },

  iframeHeightPadding: 2, // optional

  //without redirecting
  onSubmitCompleteEvent: function (event) {
    // console.log("onSubmitCompleteEvent????");
    // console.log(event);
  },

  onVindiciaFieldEvent: function (event) {
    // optional
  },
};

export const vindiciaOptionsNoCvn = {
  // Options

  formId: 'mainForm', // dom element of form

  vindiciaServer: AppEndpoint.VINDICIA_CONFIG_API,

  hostedFields: {
    cardNumber: {
      selector: '#vin_PaymentMethod_creditCard_account',
      placeholder: 'Enter Credit Card Number', // optional field
    },

    expirationDate: {
      // required field or use expirationMonth and expirationYear

      selector: '#vin_PaymentMethod_creditCard_expirationDate',
      placeholder: 'Expiration Date MM/YY', // optional field
      format: 'MM/YY', // optional, default is MM/YY
    },

    styles: {
      // optional

      input: {
        width: '100%',
        'font-family': "'Helvetica Neue',Helvetica,Arial,sans-serif",
        'font-size': '14px',
        color: gray700,
        height: '42px',
        padding: '10px 17px',
        margin: '10px 0px',
        'line-height': '1.42857',
        border: `1px solid ${gray400}`,
        'border-radius': '4px',
        'box-shadow': '0px 1px 1px rgba(0,0,0,0.075) inset',
        '-webkit-transition': 'border-color 0.15s ease-in-out 0s, box-shadow 0.15s ease-in-out 0s',
        transition: 'border-color 0.15s ease-in-out 0s, box-shadow 0.15s ease-in-out 0s',
      },

      select: {
        width: '100%',
        'font-family': "'Helvetica Neue',Helvetica,Arial,sans-serif",
        'font-size': '14px',
        color: gray700,
        height: '34px',
        padding: '6px 12px',
        margin: '5px 0px',
        'line-height': '1.42857',
        border: `1px solid ${gray200}`,
        'border-radius': '4px',
        'box-shadow': '0px 1px 1px rgba(0,0,0,0.075) inset',
        '-webkit-transition': 'border-color 0.15s ease-in-out 0s, box-shadow 0.15s ease-in-out 0s',
        transition: 'border-color 0.15s ease-in-out 0s, box-shadow 0.15s ease-in-out 0s',
      },

      ':focus': {
        // optional

        'border-color': gray100,
        outline: '0',
        '-webkit-box-shadow': '0 0 0 0.2rem rgba(14, 63, 112, 0.25)',
        'box-shadow': '0 0 0 0.2rem rgba(14, 63, 112, 0.25)',
        color: gray700,
      },

      '.valid': {
        // optional

        'border-color': green,
      },

      '.notValid': {
        // optional

        'border-color': red,
      },
    },
  },

  iframeHeightPadding: 2, // optional

  //without redirecting
  onSubmitCompleteEvent: function (event) {
    // console.log("onSubmitCompleteEvent????");
    // console.log(event);
  },

  onVindiciaFieldEvent: function (event) {
    // optional
  },
};
