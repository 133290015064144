import React, { useState, useRef, useEffect } from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Input, FormFeedback } from '@britannica/ui';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import * as Yup from 'yup';
import ReCAPTCHA from 'react-google-recaptcha';
import CircularProgress from '@material-ui/core/CircularProgress';
import classnames from 'classnames';
import './Login.scss';
import SubscribeApi from '../../services/SubscribeApi';
import Util from '../../services/Util';
import ErrorPrompt from '../ErrorPrompt/ErrorPrompt';

const Login = ({ onLogin, unAuthorizedError }) => {
  const [openModal, setOpenModal] = useState(false);
  const [isSigning, setIsSigning] = useState(false);
  const [isIntializing, setIsIntializing] = useState(false);
  // const [errorMessage, setErrorMessage] = useState(null);
  const [loginError, setLoginError] = useState(null);
  const recaptchaRef = useRef(null);

  const validationSchema = Yup.object().shape({
    email: Yup.string().email('Invalid email').required('Required'),
    password: Yup.string().max(30, 'Maximum 30 characters').required('Required'),
  });

  const { register, handleSubmit, watch, formState, errors } = useForm({
    mode: 'onBlur',
    resolver: yupResolver(validationSchema),
  });
  const watchAllFields = watch();

  useEffect(() => {
    if (unAuthorizedError) {
      setOpenModal(true);
    }
  }, [unAuthorizedError]);

  const onChange = (recaptcha, values) => {
    // console.log("recaptcha", recaptcha);
    SubscribeApi.login(values.email, values.password, recaptcha).then(
      (data) => {
        setIsSigning(false);
        if (data.result && data.result.authCookie) {
          // let cookie = data.result.authCookie;
          // CookieService.setCookie(
          //   cookie.name,
          //   cookie.value,
          //   cookie.maxAge,
          //   cookie.path,
          //   "britannica.com"
          // );
        } else {
          console.log('no cookie return from response');
        }

        if (data.status === 'SUCCESS' && data.result && data.result.token) {
          document.getElementById('loginForm').reset();
          let jwt = data.result.token.jwt;
          setIsIntializing(false);
          onLogin(jwt);
          setOpenModal(false);
          setLoginError(null);
        } else {
          setLoginError('Unexpected Error: No jwt token');
        }
      },
      (error) => {
        setIsSigning(false);
        setLoginError(Util.getErrorMessage(error));
      }
    );
  };

  const submit = () => {
    setIsSigning(true);
    if (recaptchaRef.current.getValue()) {
      let widgetId = recaptchaRef.current.getWidgetId();
      recaptchaRef.current.reset(widgetId);
      recaptchaRef.current.execute();
    } else {
      recaptchaRef.current.execute();
    }
  };

  return (
    <div className="font-18 text-muted mb-20 font-weight-bold">
      Already a member?{' '}
      <span
        onClick={() => {
          setOpenModal(!openModal);
          setLoginError(null);
        }}
        className="cursor-pointer link-navy"
      >
        LOG IN
      </span>
      <Modal
        isOpen={openModal}
        toggle={() => {
          setOpenModal(!openModal);
          setLoginError(null);
        }}
      >
        <form id="loginForm" onSubmit={handleSubmit(submit)}>
          <ModalHeader
            className="border-bottom py-15 px-30 d-flex justify-content-between align-items-center"
            toggle={() => {
              setOpenModal(!openModal);
              setLoginError(null);
            }}
          >
            <div className="font-18 font-weight-bold">Log in</div>
          </ModalHeader>
          <ModalBody>
            <div className="mb-20">
              <div className="label mb-10"> Email: </div>
              <Input
                className={classnames({
                  'border-green': formState.dirtyFields.email && !errors.email,
                })}
                size="sm"
                type="email"
                name="email"
                isInvalid={!!errors.email}
                placeholder="Enter Your Email"
                innerRef={register}
              />
              {errors.email && <FormFeedback className="font-serif">{errors.email?.message}</FormFeedback>}
            </div>

            <div>
              <div className="label mb-10"> Password: </div>
              <Input
                className={classnames({
                  'border-green': formState.dirtyFields.password && !errors.password,
                })}
                size="sm"
                type="password"
                name="password"
                isInvalid={!!errors.password}
                placeholder="Password"
                innerRef={register}
              />
              {errors.password && <FormFeedback className="font-serif">{errors.password?.message}</FormFeedback>}
            </div>

            {loginError && <ErrorPrompt displayError={true} customErrorMessage={loginError} />}
          </ModalBody>
          <ModalFooter className="border-top text-right">
            <ReCAPTCHA
              ref={recaptchaRef}
              size="invisible"
              sitekey={process.env.REACT_APP_SITEKEY}
              onChange={(recaptcha) => onChange(recaptcha, watchAllFields)}
            />

            <Button
              label="Signing in"
              color="blue"
              size="sm"
              type="submit"
              disabled={isSigning}
              className="btn btn-primary"
            >
              {isSigning || isIntializing ? (
                <>
                  <span>Signing in</span>
                  <CircularProgress className="ml-10" color="inherit" size={16} />
                </>
              ) : (
                'Sign in'
              )}
            </Button>
            <Button
              className="ml-10"
              label="Cancel"
              color="gray-600"
              size="sm"
              onClick={() => {
                setOpenModal(!openModal);
                setLoginError(null);
              }}
            >
              Cancel
            </Button>
          </ModalFooter>
        </form>
      </Modal>
    </div>
  );
};

export default Login;
