import React, { useEffect, useState, useContext } from 'react';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import classnames from 'classnames';
import { Button, FormFeedback } from '@britannica/ui';
import CircularProgress from '@material-ui/core/CircularProgress';
import { useLocation, useHistory } from 'react-router-dom';
import { vindiciaOptions, vindiciaOptionsNoCvn } from './PaymentConfig';
import LoadingContainer from '../../../components/Loading/LoadingContainer';
import SubscribeApi from '../../../services/SubscribeApi';
import AcceptedPayment from '../../../components/AcceptedPayment/AcceptedPayment';
import Util from '../../../services/Util';
import { AppEndpoint, SHIPPING_ADDRESS, BILLING_ADDRESS } from '../../../App.constants';
import { GlobalContext } from '../../../context/GlobalState';
import AddressForm from './AddressForm';
import ErrorPrompt from '../../../components/ErrorPrompt/ErrorPrompt';

const Payment = ({
  jwt,
  hasCvv,
  isBkids,
  isFreeTrial,
  isDefault,
  divisionCode,
  newWebsessionId,
  promoCode,
  handleUnAuthorized,
}) => {
  const { productId, pbId, billId } = useContext(GlobalContext);

  const [showStatesList, setShowStatesList] = useState(false);
  const [showStatesListShipping, setShowStatesListShipping] = useState(false);
  const [states, setStates] = useState(null);
  const [countries, setCountries] = useState(null);
  const [country, setCountry] = useState(null);
  const [countryInit, setCountryInit] = useState(null);
  const [countryShipping, setCountryShipping] = useState(null);
  const [errorMessage, setErrorMessage] = useState(null);
  const [cardValid, setCardValid] = useState(null);
  const [cardError, setCardError] = useState(null);
  const [expValid, setExpValid] = useState(null);
  const [expError, setExpError] = useState(null);
  const [cvvValid, setCvvValid] = useState(null);
  const [cvvError, setCvvError] = useState(null);
  const [isSubscribing, setIsSubscribing] = useState(false);
  const [activeCardCode, setActiveCardCode] = useState(null);
  // const [initializing, setInitializing] = useState(false);
  const [webSessionId, setWebSessionId] = useState(true);
  //const [initialError, setInitalError] = useState(null);
  let [hasPaymentMethod, setHasPaymentMethod] = useState(null);
  const [paymentMethodError, setPaymentMethodError] = useState(null);
  const [acceptedMethods, setAcceptedMethods] = useState([]);
  const [initialError, setInitialError] = useState(null);

  const [showPaymentForm, setShowPaymentForm] = useState(false);
  const [paymentLoading, setPaymentLoading] = useState(true);

  const [sameAddress, setSameAddress] = useState(true);

  const location = useLocation();
  const history = useHistory();

  useEffect(() => {
    if (showPaymentForm === true) {
      if (!hasCvv) {
        window.vindicia.setup(vindiciaOptionsNoCvn);
      } else {
        window.vindicia.setup(vindiciaOptions);
      }
      initilization();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showPaymentForm]);

  useEffect(() => {
    setWebSessionId(newWebsessionId);
  }, [newWebsessionId]);

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    let partnerCode = params.get('partnerCode');
    // setInitializing(true);
    SubscribeApi.getPayments({
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${jwt}`,
      },
      body: JSON.stringify({
        productId: productId,
        pbId: pbId,
        billId: billId,
        partnerCode: partnerCode,
        promotionCode: promoCode,
      }),
    }).then(
      (data) => {
        // setInitializing(false);
        //console.log("payments data", data);
        let acceptedMethods = [];
        if (data && data.status === 'SUCCESS' && data.result) {
          let paymentMethods = data.result.paymentMethods;

          if (paymentMethods.length) {
            paymentMethods.forEach((element) => {
              acceptedMethods.push(element.extPayCode);
            });
            setAcceptedMethods(acceptedMethods);
            setPaymentLoading(false);
            setShowPaymentForm(true);
          } else {
            initilization();
          }
        } else {
          setPaymentLoading(false);
          setInitialError('Server Error: No payment method available');
        }
      },
      (error) => {
        if (error.errorResponse?.code === 'UnAuthorized') {
          setInitialError('Not a valid Request. Please login before performing this action.');
          handleUnAuthorized();
        }
        setPaymentLoading(false);
        // setInitializing(true);
        setInitialError(Util.getErrorMessage(error));
      }
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    SubscribeApi.getCountries().then(
      (data) => {
        if (data.status === 'SUCCESS') {
          setCountries(data.result.countryCodes);

          //initilize country
          if (divisionCode === 'UK') {
            setCountry('GB');
            setCountryInit('GB');
            setShowStatesList(false);
          } else if (divisionCode === 'AU') {
            setCountry('AU');
            setCountryInit('AU');
            setShowStatesList(false);
          } else {
            setCountry('US');
            setCountryInit('US');
            setShowStatesList(true);
          }
        }
      },
      (error) => {
        console.log('error', error);
      }
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    SubscribeApi.getStates().then(
      (data) => {
        if (data.status === 'SUCCESS') {
          setStates(
            //sorting and put none at the top as default selection
            data.result.stateCodes.sort((x, y) => {
              if (x.code === 'NONE') {
                return -1;
              } else {
                return 1;
              }
            })
          );
        }
      },
      (error) => {
        console.log('error', error);
      }
    );
  }, []);

  useEffect(() => {
    function handleSubmitCompleteEvent(e) {
      //console.log("happen in react", e);

      //sometime hoa need more time to finalize not sure why
      setTimeout(() => {
        subscribeFun();
      }, 4000);
    }
    window.addEventListener('submitCompleteEvent', handleSubmitCompleteEvent);
    return () => window.removeEventListener('submitCompleteEvent', handleSubmitCompleteEvent);
  });

  const subscribeFun = (propWebSessionId) => {
    SubscribeApi.subscribe({
      method: 'POST',
      credentials: 'include',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${jwt}`,
      },
      body: JSON.stringify({
        webSessionId: propWebSessionId ? propWebSessionId : webSessionId,
      }),
    }).then(
      (data) => {
        if (!showPaymentForm) {
          setShowPaymentForm(false);
          setPaymentLoading(false);
        }
        setIsSubscribing(false);
        history.replace(`/confirmation/success?productId=${productId}`, {
          jwt: jwt,
          isBkids: isBkids,
          isDefault: isDefault,
          isFreeTrial: isFreeTrial,
        });
      },
      (error) => {
        setIsSubscribing(false);
        if (error.errorResponse?.code === 'UnAuthorized') {
          setErrorMessage('Not a valid Request. Please login before performing this action.');
          handleUnAuthorized();
        } else {
          if (!showPaymentForm) {
            setShowPaymentForm(false);
            setPaymentLoading(false);
            setErrorMessage(
              'We were unable to create your subscription. Please contact customer service at customerservice@eb.com'
            );
          } else {
            if (
              error &&
              error.errorResponse &&
              error.errorResponse.code === 'UserSubscriptionFailed' &&
              error.errorResponse.errors &&
              error.errorResponse.errors[0].code === 'finalizeWebSession.AccountExists'
            ) {
              history.replace(`/confirmation/success?productId=${productId}`, {
                jwt: jwt,
                isBkids: isBkids,
                isDefault: isDefault,
                isFreeTrial: isFreeTrial,
              });
            } else {
              setErrorMessage(Util.getErrorMessage(error));
            }
          }
        }

        //clear the form and should be able to submit again
        if (showPaymentForm) {
          window.vindicia.resetCompleteStatus();
        }
        initilization();
      }
    );
  };

  useEffect(() => {
    function handleVindiciaFieldEvent(event) {
      //console.log('Vindicia Field Event', event);
      if (event.detail.fieldType === 'cardNumber') {
        setActiveCardCode(event.detail.cardType); // bright or dim the card logo
        if (
          event.detail.cardType &&
          (acceptedMethods.includes(event.detail.cardType) ||
            (acceptedMethods.includes('diners') && event.detail.cardType.includes('diners')))
        ) {
          setHasPaymentMethod(true);
          setPaymentMethodError(null);
        } else {
          setHasPaymentMethod(false);
          setPaymentMethodError('Card type is not supported');
        }
        if (!event.detail.isValid) {
          setCardValid(false);
          setCardError('Card number not valid');
        } else {
          setCardValid(true);
          setCardError(null);
        }
      }
      if (event.detail.fieldType === 'expirationDate') {
        if (!event.detail.isValid) {
          setExpValid(false);
          setExpError('Expiration Date not valid');
        } else {
          setExpValid(true);
          setExpError(null);
        }
      }

      if (hasCvv && event.detail.fieldType === 'cvn') {
        if (!event.detail.isValid) {
          setCvvValid(false);
          setCvvError('CVN/CVV number not valid');
        } else {
          setCvvValid(true);
          setCvvError(null);
        }
      }
    }
    window.addEventListener('vindiciaFieldEvent', handleVindiciaFieldEvent);
    return () => window.removeEventListener('vindiciaFieldEvent', handleVindiciaFieldEvent);
  });

  const initilization = () => {
    const params = new URLSearchParams(location.search);
    let partnerCode = params.get('partnerCode');
    // setInitializing(true);
    SubscribeApi.InitializeSubscription({
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${jwt}`,
      },
      body: JSON.stringify({
        productId: productId,
        pbId: pbId,
        billId: billId,
        partnerCode: partnerCode,
        promotionCode: promoCode,
      }),
    }).then(
      (data) => {
        // setInitializing(false);
        //console.log("InitializeSubscription", data);
        if (data && data.sucessResponse && data.sucessResponse.webSessionId) {
          setWebSessionId(data.sucessResponse.webSessionId);
          // setTimeout(() => {
          //   window.vindicia.setup(vindiciaOptions);
          // }, 200);
          if (!showPaymentForm) {
            subscribeFun(data.sucessResponse.webSessionId);
          }
        }
      },
      (error) => {
        // setInitializing(true);
        if (error.errorResponse?.errors) {
          let errorString = '';
          error.errorResponse.errors.forEach((error) => {
            errorString += ' ' + error.message;
          });
          setInitialError(errorString);
        } else if (error.errorResponse?.code === 'UnAuthorized') {
          setInitialError('Not a valid Request. Please login before performing this action.');
          handleUnAuthorized();
        } else if (error?.errorResponse) {
          setInitialError(error.errorResponse.code + ': ' + error.errorResponse.errorMessage);
        } else {
          setInitialError('InitializeSubscription Error:' + error);
        }
      }
    );
  };

  const onSelectCountry = (event) => {
    setCountry(event.target.value);
    if (event.target.value === 'CA' || event.target.value === 'US') {
      setShowStatesList(true);
    } else {
      setShowStatesList(false);
    }
  };

  const onSelectCountryShipping = (event) => {
    setCountryShipping(event.target.value);
    if (event.target.value === 'CA' || event.target.value === 'US') {
      setShowStatesListShipping(true);
    } else {
      setShowStatesListShipping(false);
    }
  };

  const validationSchema = (country, countryShipping) => {
    let validationShapeBilling = {
      vin_PaymentMethod_billingAddress_name: Yup.string()
        .required('Required')
        .max(60, 'Maximum 60 characters')
        .test('Invalid characters', 'Invalid characters', (value) => Util.isPermittedChars(value)),
      vin_PaymentMethod_billingAddress_addr1: Yup.string()
        .required('Required')
        .max(70, 'Maximum 70 characters')
        .test('Invalid characters', 'Invalid characters', (value) => Util.isPermittedChars(value)),
      vin_PaymentMethod_billingAddress_addr2: Yup.string()
        .max(70, 'Maximum 70 characters')
        .test('Invalid characters', 'Invalid characters', (value) => Util.isPermittedChars(value)),

      vin_PaymentMethod_billingAddress_city: Yup.string()
        .required('Required')
        .max(30, 'Maximum 30 characters')
        .test('Invalid characters', 'Invalid characters', (value) => Util.isPermittedChars(value)),
      vin_PaymentMethod_billingAddress_country: Yup.string()
        .notOneOf(['NONE', 'No country selected', ''], 'Required')
        .max(30, 'Maximum 30 characters')
        .required('Required'),
      vin_PaymentMethod_billingAddress_postalCode: Yup.string()
        .required('Required')
        .max(20, 'Maximum 20 characters')
        .test({
          name: 'postalCode',
          exclusive: true,
          message: 'Invalid ZipCode',
          test: (value) => {
            if (country === 'US') {
              return Util.isValidUsPostalCode(value);
            } else if (country === 'GB') {
              return Util.isValidUkPostalCode(value);
            } else if (country === 'CA') {
              return Util.isValidCanadaPostalCode(value);
            }
            return true;
          },
        }),
      vin_PaymentMethod_billingAddress_district: Yup.string()
        .notOneOf(['NONE', 'No state selected', ''], 'Required')
        .max(30, 'Maximum 30 characters')
        .required('Required'),
      checkbox: Yup.boolean()
        .oneOf([true], 'Must Accept Terms and Conditions')
        .required('Must Accept Terms and Conditions'),
      vin_PaymentMethod_billingAddress_phone: Yup.string()
        .matches(
          /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/,
          'Phone number is not valid'
        )
        .max(20, 'Maximum 20 characters'),
    };

    let validationShapeShipping = {};
    if (!sameAddress) {
      validationShapeShipping = {
        vin_Account_shippingAddress_name: Yup.string()
          .required('Required')
          .max(60, 'Maximum 60 characters')
          .test('Invalid characters', 'Invalid characters', (value) => Util.isPermittedChars(value)),
        vin_Account_shippingAddress_addr1: Yup.string()
          .required('Required')
          .max(70, 'Maximum 70 characters')
          .test('Invalid characters', 'Invalid characters', (value) => Util.isPermittedChars(value)),
        vin_Account_shippingAddress_addr2: Yup.string()
          .max(70, 'Maximum 70 characters')
          .test('Invalid characters', 'Invalid characters', (value) => Util.isPermittedChars(value)),

        vin_Account_shippingAddress_city: Yup.string()
          .required('Required')
          .max(30, 'Maximum 30 characters')
          .test('Invalid characters', 'Invalid characters', (value) => Util.isPermittedChars(value)),
        vin_Account_shippingAddress_country: Yup.string()
          .notOneOf(['NONE', 'No country selected', ''], 'Required')
          .max(30, 'Maximum 30 characters')
          .required('Required'),
        vin_Account_shippingAddress_postalCode: Yup.string()
          .required('Required')
          .max(20, 'Maximum 20 characters')
          .test({
            name: 'postalCode',
            exclusive: true,
            message: 'Invalid ZipCode',
            test: (value) => {
              if (countryShipping === 'US') {
                return Util.isValidUsPostalCode(value);
              } else if (countryShipping === 'GB') {
                return Util.isValidUkPostalCode(value);
              } else if (countryShipping === 'CA') {
                return Util.isValidCanadaPostalCode(value);
              }
              return true;
            },
          }),
        vin_Account_shippingAddress_district: Yup.string()
          .notOneOf(['NONE', 'No state selected', ''], 'Required')
          .max(30, 'Maximum 30 characters')
          .required('Required'),
        vin_Account_shippingAddress_phone: Yup.string()
          .matches(
            /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/,
            'Phone number is not valid'
          )
          .max(20, 'Maximum 20 characters'),
      };
    } else {
      validationShapeShipping = {};
    }

    const validationShape = {
      ...validationShapeBilling,
      ...validationShapeShipping,
    };

    return Yup.object().shape(validationShape);
  };

  const submit = (values) => {
    if (sameAddress) {
      values.vin_Account_shippingAddress_phone = values.vin_PaymentMethod_billingAddress_phone;
      values.vin_Account_shippingAddress_country = values.vin_PaymentMethod_billingAddress_country;
      values.vin_Account_shippingAddress_district = values.vin_PaymentMethod_billingAddress_district;
      values.vin_Account_shippingAddress_postalCode = values.vin_PaymentMethod_billingAddress_postalCode;
      values.vin_Account_shippingAddress_city = values.vin_PaymentMethod_billingAddress_city;
      values.vin_Account_shippingAddress_addr2 = values.vin_PaymentMethod_billingAddress_addr2;
      values.vin_Account_shippingAddress_addr1 = values.vin_PaymentMethod_billingAddress_addr1;
      values.vin_Account_shippingAddress_name = values.vin_PaymentMethod_billingAddress_name;
    }
    setIsSubscribing(true);
  };

  const changeAddress = (e, values) => {
    values.vin_Account_shippingAddress_phone = '';
    values.vin_Account_shippingAddress_country = '';
    values.vin_Account_shippingAddress_district = '';
    values.vin_Account_shippingAddress_postalCode = '';
    values.vin_Account_shippingAddress_city = '';
    values.vin_Account_shippingAddress_addr2 = '';
    values.vin_Account_shippingAddress_addr1 = '';
    values.vin_Account_shippingAddress_name = '';

    let addressValue = JSON.parse(e.target.value);
    if (addressValue) {
      setSameAddress(false);
    } else {
      setSameAddress(true);
    }
  };

  return (
    <LoadingContainer
      isLoading={jwt.length === 0 || paymentLoading}
      hasError={initialError}
      errorMessage={initialError}
    >
      <Formik
        initialValues={{
          vin_PaymentMethod_billingAddress_name: '',
          vin_PaymentMethod_billingAddress_addr1: '',
          vin_PaymentMethod_billingAddress_addr2: '',
          vin_PaymentMethod_billingAddress_city: '',
          vin_PaymentMethod_billingAddress_district: '',
          vin_PaymentMethod_billingAddress_country: countryInit,
          vin_PaymentMethod_billingAddress_postalCode: '',
          vin_PaymentMethod_billingAddress_phone: '',
          checkbox: false,
          vin_Account_shippingAddress_name: '',
          vin_Account_shippingAddress_addr1: '',
          vin_Account_shippingAddress_addr2: '',
          vin_Account_shippingAddress_city: '',
          vin_Account_shippingAddress_district: '',
          vin_Account_shippingAddress_country: '',
          vin_Account_shippingAddress_postalCode: '',
          vin_Account_shippingAddress_phone: '',
        }}
        validationSchema={validationSchema(country, countryShipping)}
        onSubmit={submit}
        enableReinitialize={true}
      >
        {({ errors, touched, values, setFieldTouched, setFieldValue, setFieldError }) => (
          <Form
            className={`form-horizontal ${!showPaymentForm && 'd-none'}`}
            id="mainForm"
            action={AppEndpoint.VINDICIA_API}
            method="post"
          >
            <fieldset disabled={isSubscribing}>
              <div className="font-18 my-15 text-gray-600">Payment Method</div>
              <AcceptedPayment acceptedMethods={acceptedMethods} activeCardCode={activeCardCode} />

              <Field type="hidden" name="vin_WebSession_version" value="21.0" />

              <input type="hidden" name="vin_WebSession_vid" value={webSessionId} />

              <input type="hidden" name="vin_PaymentMethod_customerSpecifiedType" value={activeCardCode} />

              <input type="hidden" name="vin_PaymentMethod_type" value="CreditCard" />

              <div id="vin_PaymentMethod_creditCard_account" />
              {cardError && <FormFeedback className="font-serif">{cardError}</FormFeedback>}
              {paymentMethodError && <FormFeedback className="font-serif">{paymentMethodError}</FormFeedback>}

              <div id="vin_PaymentMethod_creditCard_expirationDate" />
              {expError && <FormFeedback className="font-serif">{expError}</FormFeedback>}

              {hasCvv && <div id="vin_PaymentMethod_nameValues_cvn" />}
              {hasCvv && cvvError && <FormFeedback className="font-serif">{cvvError}</FormFeedback>}

              <div id="vin_PaymentMethod_test" />

              <AddressForm
                addressFor={BILLING_ADDRESS}
                showStatesList={showStatesList}
                Field={Field}
                touched={touched}
                errors={errors}
                states={states}
                country={country}
                values={values}
                onSelectCountry={onSelectCountry}
                countries={countries}
                setFieldValue={setFieldValue}
                setFieldError={setFieldError}
              />
              {/* AddressForm 1 */}

              <div className="mb-20">
                <label className="d-flex">
                  <Field
                    type="checkbox"
                    id="toggleSwitch"
                    className="mt-5"
                    value={JSON.stringify(sameAddress)}
                    checked={sameAddress}
                    onChange={(e) => changeAddress(e, values)}
                  />
                  <span className="ml-15">Shipping address is same as Billing address</span>
                </label>
              </div>

              <div className={classnames({ 'd-none': sameAddress })}>
                <AddressForm
                  addressFor={SHIPPING_ADDRESS}
                  showStatesList={showStatesListShipping}
                  Field={Field}
                  touched={touched}
                  errors={errors}
                  states={states}
                  country={countryShipping}
                  values={values}
                  onSelectCountry={onSelectCountryShipping}
                  countries={countries}
                  setFieldValue={setFieldValue}
                  setFieldError={setFieldError}
                />
              </div>
              {/* AddressForm 2 */}

              <div className="mb-20">
                <label className="d-flex">
                  <Field
                    type="checkbox"
                    name="checkbox"
                    id="checkbox"
                    className={classnames('mt-5', touched.checkbox && (errors.checkbox ? 'is-invalid' : 'is-valid'))}
                    onChange={(e) => {
                      values.checkbox = !values.checkbox;
                      setFieldTouched('checkbox', true);
                    }}
                  />

                  <span className="ml-15">
                    By checking this box, I confirm that I am at least 16 years of age, and that I accept the Automatic
                    Renewal Terms set forth below, all of the{' '}
                    <a
                      target="_blank"
                      href="https://corporate.britannica.com/termsofuse.html"
                      rel="noopener noreferrer"
                    >
                      Terms of Use{' '}
                    </a>
                    and our{' '}
                    <a target="_blank" href="https://corporate.britannica.com/privacy.html" rel="noopener noreferrer">
                      Privacy Policy
                    </a>
                    .
                  </span>
                </label>
                <ErrorMessage className="font-serif" name="checkbox" component={FormFeedback} />
              </div>
              {/* I Agree */}

              <div className="my-25">
                <Button
                  color="blue"
                  size="sm"
                  disabled={
                    !(
                      Object.keys(touched).length !== 0 &&
                      Object.keys(errors).length === 0 &&
                      cardValid &&
                      (!hasCvv || cvvValid) &&
                      expValid &&
                      hasPaymentMethod
                    )
                  }
                  type="submit"
                  id="submitButton"
                  label={isSubscribing ? 'Creating Subscription' : 'Subscribe'}
                  value="Submit"
                >
                  {isSubscribing ? (
                    <>
                      <span>Creating Subscription</span>
                      <CircularProgress className="ml-10" color="inherit" size={16} />
                    </>
                  ) : (
                    'Subscribe'
                  )}
                </Button>
              </div>
              {/* Subscribe CTA */}
            </fieldset>
          </Form>
        )}
      </Formik>

      {errorMessage && <ErrorPrompt displayError={true} customErrorMessage={errorMessage} />}
    </LoadingContainer>
  );
};

export default Payment;
