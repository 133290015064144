import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import SubscribeSuccess from './pages/subsribeSuccess/SubscribeSuccess';
import MainPage from './pages/mainPage/MainPage';

const AppRoutes = (location) => (
  <Switch>
    <Route path="/subscribe" query={location.query} component={MainPage} />
    <Route
      exact
      path="/"
      query={location.query}
      render={(location) => <Redirect to={{ ...location, pathname: '/subscribe' }} />}
    />
    {/* <Route path='/subscribe' component={MainPage} /> */}
    <Route exact path="/confirmation/success" query={location.query} component={SubscribeSuccess} />
  </Switch>
);

export default AppRoutes;
