import React, { useState, useEffect } from 'react';
import { Container, Button } from '@britannica/ui';
import CookieService from '../../services/CookieService';
import SubscribeApi from '../../services/SubscribeApi';
import Header from '../../components/Header/Header';

const SubscribeSuccess = ({ location, history }) => {
  // console.log("hi, SubscribeSuccess!!!!!!");
  // const [pageData, setPageData] = useState(null);
  // const [displayDescription, setDisplayDescription] = useState('');

  const [isBkids, setIsBkids] = useState(false);
  const [isFreeTrial, setIsFreeTrial] = useState(false);
  const [isDefault, setIsDefault] = useState(false);

  const [productURL, setProductURL] = useState('');
  const [verbiage, setVerbiage] = useState('');

  const [csAccountId, setCsAccountId] = useState('');
  const [csAccountErr, setCsAccountErr] = useState(false);
  // console.log("location.state", location.state);

  useEffect(() => {
    setTimeout(() => {
      if (window.ga && window.dataLayer) {
        console.log('event sent');
        window.ga('send', 'pageview', '/confirmation/success');
        window.dataLayer.push({ event: 'pageview' });
      }
    }, 500);
  }, []);

  useEffect(() => {
    const getContent = (location) => {
      const params = new URLSearchParams(location.search);
      const productId = params.get('productId');
      let jwt;
      let isFreeTrial;
      let isBkids;
      let isDefault;

      if (location && location.state) {
        jwt = location.state.jwt;
        isFreeTrial = location.state.isFreeTrial;
        isBkids = location.state.isBkids;
        isDefault = location.state.isDefault;
        setIsFreeTrial(isFreeTrial);
        setIsBkids(isBkids);
        setIsDefault(isDefault);
      }
      SubscribeApi.getConfirmation(productId, {
        method: 'GET',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: `Bearer ${jwt}`,
        },
      }).then(
        (data) => {
          if (data.status === 'SUCCESS' && data.result.productInfo) {
            // setDisplayDescription(data.result.productInfo.displayDescription);
            setProductURL(data.result.productInfo.productURL);
            setVerbiage(data.result.productInfo.verbiage);
            if (data.result.productInfo.csProduct === true) {
              getCsActivationLink();
            }
          }
        },
        (error) => {
          console.log('error', error);
        }
      );

      // SubscribeApi.getConfirmation(productId).then(
      //   data => {
      //     setPageData(data);
      //   },
      //   error => {
      //     setPageData({});
      //   }
      // );
    };
    getContent(location);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  const getHref = () => {
    // console.log("get cookie content:", CookieService.getCookie("subreturn"));

    return CookieService.getCookie('subreturn')
      ? decodeURIComponent(CookieService.getCookie('subreturn'))
      : 'https://www.britannica.com';
  };

  const getCsActivationLink = () => {
    const jwt = location?.state?.jwt;
    SubscribeApi.getAccountDetails({
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${jwt}`,
      },
    }).then(
      (data) => {
        if (data) {
          setCsAccountId(data.accountId);
          setCsAccountErr(false);
        }
      },
      (error) => {
        setCsAccountId('');
        setCsAccountErr(true);
      }
    );
  };

  return (
    <>
      <Header isFreeTrial={isFreeTrial} isBkids={isBkids} isDefault={isDefault} />
      <Container className="font-20 pb-20">
        {/* <div className="confirm-title">
        Thank you for joining the Britannica Family --{displayDescription}
        <span> {pageData && pageData.displayDescription}</span>. You should
        receive an email shortly with your details about your account.
        <span>{pageData && pageData.verbiage}</span>
        You can update your account by accessing the{" "}
        <a href="https://myaccount.britannica.com"> My Accounts Page</a>.
      </div> */}
        <div className="mt-20">
          <div dangerouslySetInnerHTML={{ __html: verbiage }} />
          If you have issues, please contact{' '}
          <a href="http://forms01.britannica.com/help/bpshelpform.html">Customer Service</a>
        </div>
        <div className="mt-15">
          {productURL ? (
            <a href={productURL}>
              <Button size="sm" color="blue" className="btn btn-primary">
                Continue
              </Button>
            </a>
          ) : (
            <a href={getHref()}>
              <Button size="sm" color="blue" label="Continue">
                Continue
              </Button>
            </a>
          )}
        </div>

        {csAccountId && (
          <>
            <p className="mt-25">
              To access your Curiosity Stream subscriptions please click the link below to set up your account.
              Instructions will also be sent to your email so you can set it up when you are ready. If you do not
              receive your email, please check your promotions or spam folder. You can also save our email address (
              <a href="mailto:noreply@email.britannica.com">noreply@email.britannica.com</a>) to your address book to
              ensure you receive communications from us consistently.
            </p>
            <a
              target="_blank"
              rel="noopener noreferrer"
              href={`${process.env.REACT_APP_CURIOSITY_STREAM_URL}${csAccountId}`}
            >
              <Button size="sm" color="blue" label="Activate Curiosity Stream Account">
                Activate Curiosity Stream Account
              </Button>
            </a>
          </>
        )}

        {csAccountErr && (
          <p className="mt-25">
            Instructions on how to access your Curiosity Stream subscription will be sent to you via email. Follow the
            link in that email to set up your account. If you do not receive your email, please check your promotions or
            spam folder. You can also save our email address (
            <a href="mailto:noreply@email.britannica.com">noreply@email.britannica.com</a>) to your address book to
            ensure you receive communications from us consistently.
          </p>
        )}
      </Container>
    </>
  );
};

export default SubscribeSuccess;
