import FetchManager from './FetchManager';
import { AppEndpoint } from '../App.constants';

class SubscribeApi extends FetchManager {
  getParterners(partnerCode, utmParams) {
    return this.fetch(`${AppEndpoint.BACKEND_API}/subscription/partners/${partnerCode}?${utmParams}`, {
      credentials: 'include',
    });
  }

  getmembershipPlans(partnerCode) {
    return this.fetch(`${AppEndpoint.BACKEND_API}/subscription/partners/${partnerCode}/products`);
  }

  getConfirmation(productId, options) {
    return this.fetch(`${AppEndpoint.BACKEND_API}/subscription/products/${productId}`, options);
  }

  getAccountDetails(options) {
    return this.fetch(`${AppEndpoint.BACKEND_API}/subscription/users`, options);
  }

  getSecurityQuestions() {
    return this.fetch(`${AppEndpoint.BACKEND_API}/subscription/securityQuestions`);
  }

  login(email, password, recaptcha) {
    return this.fetch(`${AppEndpoint.BACKEND_API}/subscription/authenticate`, {
      method: 'POST',
      body: JSON.stringify({ email, password, recaptcha }),
      credentials: 'include',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    });
  }

  InitializeSubscription(options) {
    return this.fetch(`${AppEndpoint.BACKEND_API}/subscription/InitializeSubscription`, options);
  }

  getCountries() {
    return this.fetch(`${AppEndpoint.BACKEND_API}/subscription/countries`);
  }

  getStates() {
    return this.fetch(`${AppEndpoint.BACKEND_API}/subscription/states`);
  }

  createAccount(userInfo) {
    return this.fetch(`${AppEndpoint.BACKEND_API}/subscription/users`, {
      method: 'POST',
      body: JSON.stringify(userInfo),
      credentials: 'include',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    });
  }

  subscribe(options) {
    return this.fetch(`${AppEndpoint.BACKEND_API}/subscription/subscriptions`, options);
  }

  getPayments(options) {
    return this.fetch(`${AppEndpoint.BACKEND_API}/subscription/paymentMethods`, options);
  }

  submitBillingInfo(billingInfo) {
    const dataString = Object.keys(billingInfo)
      .map((key) => `${encodeURIComponent(key)}=${encodeURIComponent(billingInfo[key].value)}`)
      .join('&');

    return this.fetch(AppEndpoint.VINDICIA_API, {
      method: 'POST',
      body: dataString,
    });
  }

  validatePromotionCode(promotionCode) {
    return this.fetch(`${AppEndpoint.BACKEND_API}/subscription/promotions/${promotionCode}`);
  }

  getPromotionalMembershipPlans(promotionCode) {
    return this.fetch(`${AppEndpoint.BACKEND_API}/subscription/promotions/${promotionCode}/products`);
  }
}

export default new SubscribeApi();
