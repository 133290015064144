import React from 'react';
import './App.scss';
import { BrowserRouter } from 'react-router-dom';
import AppRoutes from './AppRoutes';

import { GlobalProvider } from './context/GlobalState';

function App() {
  return (
    <BrowserRouter>
      <GlobalProvider>
        <div className="App">
          <AppRoutes />
        </div>
      </GlobalProvider>
    </BrowserRouter>
  );
}

export default App;
