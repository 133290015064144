import React, { useState, useEffect, useContext } from 'react';
import classnames from 'classnames';
import { Container } from '@britannica/ui';
import Header from '../../components/Header/Header';
import ImageBand from '../../components/ImageBand/ImageBand';
import CheckMark from '../../components/CheckMark/CheckMark';
import Login from '../../components/Login/Login';
import Membership from './membership/Membership';
import CreateAccount from './createAccount/CreateAccount';
import Payment from './payment/Payment';
import Terms from '../../components/Terms/Terms';
import SubscribeApi from '../../services/SubscribeApi';
import ErrorPrompt from '../../components/ErrorPrompt/ErrorPrompt';
import PromoBox from '../../components/PromoBox/PromoBox';
import { GlobalContext } from '../../context/GlobalState';
import Collapse from '../../components/Collapse/Collapse';

function MainPage(location) {
  const { billId, verbiage, selectBillId } = useContext(GlobalContext);

  const [openMembership, setOpenMembership] = useState(false);
  const [openCreateAccount, setOpenCreateAccount] = useState(false);
  const [openPayment, setOpenPayment] = useState(false);
  const [jwt, setJwt] = useState(null);
  const [content, setContent] = useState('');
  const [description, setDescription] = useState('');
  const [hasError, setHasError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [hasCvv, setHasCvv] = useState(true);
  const [isBkids, setIsBkids] = useState(false);
  const [isFreeTrial, setIsFreeTrial] = useState(false);
  const [isDefault, setIsDefault] = useState(false);
  const [divisionCode, setDivisionCode] = useState(null);
  const [websessionId, setWebsessionId] = useState(null);

  const [showMembership, setShowMembership] = useState(false);
  const [showPromoBox, setShowPromoBox] = useState(false);
  const [promoCode, setPromoCode] = useState('');
  const [showBilling, setShowBilling] = useState(true);
  const [unAuthorizedError, setUnAuthorizedError] = useState(false);

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    let partnerCode = params.get('partnerCode');
    if (!partnerCode) {
      partnerCode = 'invalid';
    }

    //utm cookie
    let utmParams = '';
    for (var value of params.keys()) {
      if (value.indexOf('utm_') > -1) {
        utmParams += value + '=' + params.get(value) + '&';
      }
    }

    SubscribeApi.getParterners(partnerCode, utmParams).then(
      (data) => {
        if (data.status === 'SUCCESS' && data.result) {
          //header image
          setIsBkids(data.result.isBkids);
          setIsFreeTrial(data.result.isFreeTrial);
          setIsDefault(data.result.defaultFlag);

          //header verbiage
          setContent(data.result.vinVerbiage.content);
          setDescription(data.result.vinVerbiage.description);

          //payment
          setDivisionCode(data.result.divisionCode);
          setHasCvv(data.result.preAuthorization);

          if (data.result?.showBox === true) {
            setShowPromoBox(true);
          } else {
            setShowMembership(true);
            setOpenMembership(true);
          }
        } else {
          if (data.status === 'FAIL' && data.errorResponse && data.errorResponse.code === 'InvalidPartnerCode') {
            if (data.defaultPartnerCode) {
              window.location.href = replacePartnerCode(data.defaultPartnerCode);
            } else {
              console.log('error no default partnerCode');
              setHasError(true);
              setErrorMessage(data.errorResponse.errorMessage);
            }
          }
        }
      },
      (error) => {}
    );
  }, [location]);

  const onUpdateWebsessionIdHandler = (websessionId) => {
    setWebsessionId(websessionId);
  };

  const onSubmitPlanHandler = () => {
    setShowBilling(true);
    if (!jwt) {
      setOpenCreateAccount(!openCreateAccount);
      setOpenMembership(false);
      setOpenPayment(false);
    } else {
      setOpenCreateAccount(false);
      setOpenMembership(false);
      setOpenPayment(true);
    }
  };

  const onCreateAccountHandler = (jwt) => {
    setJwt(jwt);
    setOpenMembership(false);
    setOpenPayment(true);
    setOpenCreateAccount(false);
    setUnAuthorizedError(false);
  };

  const resetVindicia = () => {
    if (window.vindicia) {
      window.vindicia.destroy();
    }
  };

  const handleUnAuthorized = () => {
    resetVindicia();
    setTimeout(() => {
      setJwt('');
      setUnAuthorizedError(true);
      setOpenCreateAccount(true);
    }, 2000);
  };

  const onLoginHandler = (jwt) => {
    setJwt(jwt);
    setOpenMembership(false);
    setOpenPayment(true);
    setOpenCreateAccount(false);
    setUnAuthorizedError(false);
    setShowBilling(true);
  };

  function replacePartnerCode(value) {
    const partnerCode = value;
    return `/subscribe?partnerCode=${partnerCode}`;
  }

  const checkValidPromo = (promoCode) => {
    setShowMembership(false);
    resetVindicia();
    setShowBilling(false);
    setOpenCreateAccount(false);
    selectBillId('');
    if (promoCode) {
      setPromoCode(promoCode);
      setShowMembership(true);
      setOpenMembership(true);
      setOpenPayment(false);
    } else {
      setPromoCode('');
      setShowMembership(false);
      setOpenMembership(false);
    }
  };

  return (
    <>
      <Header isBkids={isBkids} isDefault={isDefault} isFreeTrial={isFreeTrial} />

      <Container>
        {hasError && <ErrorPrompt displayError={hasError} customErrorMessage={errorMessage} />}

        <ImageBand content={content} description={description} />

        {showPromoBox && <PromoBox checkValidPromo={checkValidPromo} />}
        {/* Promo Box */}

        <hr className="my-20 bg-gray-600" />
        <div
          className={classnames('h2 font-weight-bold mb-15 d-flex align-items-center', {
            'text-gray-400 pe-none': showPromoBox && !promoCode,
          })}
          onClick={() => {
            setOpenMembership(!openMembership);
            setOpenCreateAccount(false);
            setOpenPayment(false);
          }}
        >
          Step 1: Choose Your Membership Offer {billId && <CheckMark />}
        </div>
        {showMembership && (
          <Collapse isOpen={showPromoBox ? promoCode && openMembership : openMembership}>
            <Membership
              promoCode={promoCode}
              onSubmitPlan={onSubmitPlanHandler}
              onUpdateWebsessionId={onUpdateWebsessionIdHandler}
              jwt={jwt}
              handleUnAuthorized={handleUnAuthorized}
            />
          </Collapse>
        )}
        <hr className="my-20 bg-gray-600" />

        <div
          className={classnames(
            'h2 font-weight-bold mb-15 d-flex align-items-center',
            { 'pe-none': jwt },
            { 'text-gray-400': !jwt && !billId }
          )}
          onClick={() => {
            setOpenCreateAccount(!openCreateAccount);
            setOpenMembership(false);
            setOpenPayment(false);
          }}
        >
          Step 2: Create Your Account{jwt && <CheckMark />}
        </div>

        {billId && (
          <Collapse isOpen={openCreateAccount}>
            <Login onLogin={onLoginHandler} unAuthorizedError={unAuthorizedError} />
            <CreateAccount onCreateAccount={onCreateAccountHandler} unAuthorizedError={unAuthorizedError} />
          </Collapse>
        )}

        <hr className="my-20 bg-gray-600" />
        <div
          className={classnames('h2 font-weight-bold mb-15 d-flex align-items-center', {
            'text-gray-400': !jwt,
          })}
          onClick={() => {
            setOpenPayment(!openPayment);
            setOpenMembership(false);
            setOpenCreateAccount(false);
          }}
        >
          Step 3: Billing Information
        </div>
        {jwt && showBilling && (
          <Collapse isOpen={openPayment}>
            <Payment
              jwt={jwt}
              hasCvv={hasCvv}
              isBkids={isBkids}
              isDefault={isDefault}
              isFreeTrial={isFreeTrial}
              divisionCode={divisionCode}
              newWebsessionId={websessionId}
              promoCode={promoCode}
              handleUnAuthorized={handleUnAuthorized}
            />
          </Collapse>
        )}
        <hr className="my-20 bg-gray-600" />

        <Terms verbiage={verbiage} />
      </Container>
    </>
  );
}

export default MainPage;
