import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import './ErrorPrompt.scss';

const ErrorPrompt = (props) => {
  const { children, className, customErrorMessage, displayError } = props;

  return (
    <>
      {displayError ? (
        <div color="danger" className={classnames('p-15 text-red border border-red rounded-lg mt-15', className)}>
          <div>{customErrorMessage}</div>
        </div>
      ) : (
        children
      )}
    </>
  );
};

ErrorPrompt.propTypes = {
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.array, PropTypes.element, PropTypes.bool]).isRequired,
  className: PropTypes.string,
  displayError: PropTypes.bool,
  customErrorMessage: PropTypes.string,
  onReload: PropTypes.func,
};

ErrorPrompt.defaultProps = {
  className: null,
  customErrorMessage: 'Ocorreu um erro', // <-- "An error has occurred"
  displayError: false,
  onReload: null,
};

export default ErrorPrompt;
