import React, { useState } from 'react';
import { Input, Button, FormFeedback } from '@britannica/ui';
import CircularProgress from '@material-ui/core/CircularProgress';
import { Formik, ErrorMessage, Form } from 'formik';
import * as Yup from 'yup';
import SubscribeApi from '../../services/SubscribeApi';
import ErrorPrompt from '../ErrorPrompt/ErrorPrompt';

export default function PromoBox({ checkValidPromo }) {
  const [errorMsg, setErrorMsg] = useState('');

  const submitPromo = (values, { setSubmitting }) => {
    if (values?.promotionCode) {
      SubscribeApi.validatePromotionCode(values.promotionCode).then(
        (data) => {
          setSubmitting(false);
          setErrorMsg('');
          if (data?.isValidPromotionCode) {
            checkValidPromo(values.promotionCode);
          }
        },
        (error) => {
          setSubmitting(false);
          checkValidPromo(false);
          setErrorMsg(error.message ? error.message : 'Invalid Promotion Code');
        }
      );
    }
  };

  const validationSchema = () =>
    Yup.object().shape({
      promotionCode: Yup.string().required('Required').min(2),
    });

  return (
    <Formik
      initialValues={{
        promotionCode: '',
      }}
      validationSchema={validationSchema}
      onSubmit={submitPromo}
    >
      {({ values, handleBlur, handleSubmit, isSubmitting, setFieldValue }) => (
        <>
          <Form onSubmit={handleSubmit}>
            <div className="grid g-0">
              <div className="col-100 col-sm-60 col-md-50 col-lg-40">
                <div className="grid gx-10 gx-sm-15 gx-md-20">
                  <div className="col">
                    <Input
                      type="text"
                      size="sm"
                      name="promotionCode"
                      onChange={(e) => {
                        setFieldValue('promotionCode', e.target.value);
                        setErrorMsg('');
                      }}
                      onBlur={handleBlur}
                      value={values.promotionCode}
                      placeholder="Enter Promotional Code"
                    />
                  </div>
                  <div className="col-auto">
                    <Button label="Continue" color="blue" size="sm" type="submit" disabled={isSubmitting}>
                      {isSubmitting ? (
                        <>
                          <span>Continue</span>
                          <CircularProgress className="ml-10" color="inherit" size={16} />
                        </>
                      ) : (
                        'Continue'
                      )}
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </Form>
          <ErrorMessage className="font-serif" name="promotionCode" component={FormFeedback} />
          {errorMsg && <ErrorPrompt displayError={true} customErrorMessage={errorMsg} />}
        </>
      )}
    </Formik>
  );
}
