import React, { useState, useRef, useEffect, useContext } from 'react';
import { useForm } from 'react-hook-form';
import { Input, Button, FormFeedback } from '@britannica/ui';
import CircularProgress from '@material-ui/core/CircularProgress';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import classnames from 'classnames';
import ReCAPTCHA from 'react-google-recaptcha';
import SubscribeApi from '../../../services/SubscribeApi';
import './CreateAccount.scss';
// import CookieService from '../../services/CookieService';
import Util from '../../../services/Util';
import { GlobalContext } from '../../../context/GlobalState';
import ErrorPrompt from '../../../components/ErrorPrompt/ErrorPrompt';

const CreateAccount = ({ onCreateAccount, unAuthorizedError }) => {
  const { productId, pbId, billId } = useContext(GlobalContext);

  const [isCreatingAccount, setIsCreatingAccount] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const [hideForm, setHideForm] = useState(false);

  const recaptchaRef = useRef(null);
  const passwordString =
    'Your password must be between 8 and 14 characters long and it needs at least one uppercase letter, one lowercase letter, one numeric character and one special character. ';

  const validationSchema = Yup.object().shape({
    firstName: Yup.string()
      .min(1, 'Minimum 1 characters')
      .max(30, 'Maximum 30 characters')
      .test('Invalid characters', 'Invalid characters', (value) => Util.isPermittedChars(value))
      .required('Required'),
    lastName: Yup.string()
      .min(2, 'Minimum 2 characters')
      .max(30, 'Maximum 30 characters')
      .test('Invalid characters', 'Invalid characters', (value) => Util.isPermittedChars(value))
      .required('Required'),
    email: Yup.string().email('Invalid email').max(60, 'Maximum 60 characters').required('Required'),
    confirmEmail: Yup.string()
      .oneOf([Yup.ref('email'), null], 'Email must match')
      .required('Email confirm is required'),
    password: Yup.string()
      .min(8, passwordString)
      .max(14, passwordString)
      .matches(/[a-z]/, passwordString)
      .matches(/[A-Z]/, passwordString)
      .matches(/[0-9]/, passwordString)
      .matches(/[*$!%?&@]/, passwordString)
      .required('Required'),
    confirmPassword: Yup.string()
      .oneOf([Yup.ref('password'), null], 'Password must match')
      .required('Password confirm is required'),
  });

  const { register, handleSubmit, watch, formState, errors } = useForm({
    mode: 'onBlur',
    resolver: yupResolver(validationSchema),
  });
  const watchAllFields = watch();
  const { isValid } = formState;

  const onChange = (recaptcha, values) => {
    const params = new URLSearchParams(window.location.search);
    let partnerCode = params.get('partnerCode');

    var userInfo = {
      email: values.email,
      password: values.password,
      firstName: values.firstName,
      lastName: values.lastName,
      partnerCode: partnerCode,
      productId: productId,
      billId: billId,
      pbId: pbId,
      recaptcha: recaptcha,
    };
    if (recaptcha) {
      SubscribeApi.createAccount(userInfo).then(
        (data) => {
          if (data.status === 'SUCCESS') {
            //set cookie
            if (data.result.authCookie) {
              // console.log("set cookie", data.result.authCookie);
              // let cookie = data.result.authCookie;
              // CookieService.setCookie(
              //   cookie.name,
              //   cookie.value,
              //   cookie.maxAge,
              //   cookie.path,
              //   "britannica.com"
              // );
            } else {
              console.log('no cookie return from response');
            }

            //update subscription
            let jwt = data.result.token.jwt;
            onCreateAccount(jwt);
            document.getElementById('registerForm').reset();
            setIsCreatingAccount(false);
          } else {
            setErrorMessage('Unexpected Error: Please try again');
          }
        },
        (error) => {
          setIsCreatingAccount(false);
          setErrorMessage(Util.getErrorMessage(error));
        }
      );
    }
  };

  const submit = () => {
    setIsCreatingAccount(true);
    if (recaptchaRef.current.getValue()) {
      let widgetId = recaptchaRef.current.getWidgetId();
      recaptchaRef.current.reset(widgetId);
      recaptchaRef.current.execute();
    } else {
      recaptchaRef.current.execute();
    }
  };

  useEffect(() => {
    if (unAuthorizedError) {
      setHideForm(true);
    }
  }, [unAuthorizedError]);

  return (
    <div className={classnames({ 'd-none': hideForm })}>
      <form id="registerForm" onSubmit={handleSubmit(submit)}>
        <div className="grid g-20">
          <div className="col-100 col-sm-50">
            <Input
              size="sm"
              className={classnames({
                'border-green': formState.dirtyFields.firstName && !errors.firstName,
              })}
              name="firstName"
              isInvalid={!!errors.firstName}
              placeholder="First Name"
              innerRef={register}
            />
            {errors.firstName && <FormFeedback className="font-serif">{errors.firstName?.message}</FormFeedback>}
          </div>
          {/* First Name */}

          <div className="col-100 col-sm-50">
            <Input
              className={classnames({
                'border-green': formState.dirtyFields.lastName && !errors.lastName,
              })}
              size="sm"
              name="lastName"
              isInvalid={!!errors.lastName}
              placeholder="Last Name"
              innerRef={register}
            />
            {errors.lastName && <FormFeedback className="font-serif">{errors.lastName?.message}</FormFeedback>}
          </div>
          {/* Last Name */}

          <div className="col-100 col-sm-50">
            <Input
              size="sm"
              className={classnames({
                'border-green': formState.dirtyFields.email && !errors.email,
              })}
              type="email"
              name="email"
              isInvalid={!!errors.email}
              placeholder="Enter Your Email"
              innerRef={register}
            />
            {errors.email && <FormFeedback className="font-serif">{errors.email?.message}</FormFeedback>}
          </div>
          {/* Email */}

          <div className="col-100 col-sm-50">
            <Input
              size="sm"
              className={classnames({
                'border-green': formState.dirtyFields.confirmEmail && !errors.confirmEmail,
              })}
              name="confirmEmail"
              isInvalid={!!errors.confirmEmail}
              placeholder="Confirm Email"
              innerRef={register}
            />
            {errors.confirmEmail && <FormFeedback className="font-serif">{errors.confirmEmail?.message}</FormFeedback>}
          </div>
          {/* Confirm Email */}

          <div className="col-100 col-sm-50">
            <Input
              size="sm"
              className={classnames({
                'border-green': formState.dirtyFields.password && !errors.password,
              })}
              type="password"
              name="password"
              isInvalid={!!errors.password}
              placeholder="Create Password"
              innerRef={register}
            />
            {errors.password && <FormFeedback className="font-serif">{errors.password?.message}</FormFeedback>}
          </div>
          {/* Password */}

          <div className="col-100 col-sm-50">
            <Input
              size="sm"
              className={classnames({
                'border-green': formState.dirtyFields.confirmPassword && !errors.confirmPassword,
              })}
              type="password"
              name="confirmPassword"
              isInvalid={!!errors.confirmPassword}
              placeholder="Confirm Password"
              innerRef={register}
            />
            {errors.confirmPassword && (
              <FormFeedback className="font-serif">{errors.confirmPassword?.message}</FormFeedback>
            )}
          </div>
          {/* Confirm Password */}
        </div>

        {errorMessage && <ErrorPrompt displayError={true} customErrorMessage={errorMessage} />}

        <div>
          <ReCAPTCHA
            ref={recaptchaRef}
            size="invisible"
            sitekey={process.env.REACT_APP_SITEKEY}
            onChange={function (recaptcha) {
              return onChange(recaptcha, watchAllFields);
            }}
          />
          <div className="my-25">
            <Button
              label={isCreatingAccount ? 'Creating Account' : 'Continue'}
              color="blue"
              size="sm"
              type="submit"
              disabled={isCreatingAccount || !isValid}
            >
              {isCreatingAccount ? (
                <>
                  <span>Creating Account</span>
                  <CircularProgress className="ml-10" color="inherit" size={16} />
                </>
              ) : (
                'Continue'
              )}
            </Button>
          </div>
        </div>
      </form>
    </div>
  );
};

export default CreateAccount;
